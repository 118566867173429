import React, { Component } from "react";
import "./MyDevices.css";
import "../common.css";
import config from '../../config';
import { FaPlus } from "react-icons/fa";
import { MdEdit, MdDelete, MdInfo } from "react-icons/md";
import { Link } from "react-router-dom";
import errorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";
import utilityFunctions from "../../store/utilityFunctions";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import {Card, CardContent} from "@material-ui/core";
Modal.setAppElement('body');

export default class MyDevices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            myDevices: [],
            noProfile: false,
            loading: true,
            showDeleteModal: false,
            showIosPopup: false
        };
    };

    componentDidMount() {
        this.retrieveDevices();
    };
    retrieveDevices = () => {
        this.setState({loading: true});
        this.props.protectedFetch(config.backendServer+'getuserprofile', null, errorFunctions.checkResponseThenJson)
            .then(profileRes => {
                if (!profileRes.firstName) {
                    // User profile doesn't exist, put warning on page
                    this.setState({loading: false, noProfile: true});
                } else {
                    this.props.protectedFetch(config.backendServer+'getdevices', null, errorFunctions.checkResponseThenJson)
                        .then(res => {
                            this.setState({loading: false, myDevices: res});
                        }).catch(()=> {
                        console.log('fail');
                        this.setState({loading: false});
                    });
                }
            });
    };
    downloadProofOfPurchase = id => {
        // Can't download files on iOS. If iOS, set pop-up saying 'please use computer instead'
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS) {
            this.setState({showIosPopup: true});
            return; // Exit function now
        }

        this.setState({loading: true});
        this.props.protectedFetch(config.backendServer+'downloadfile', {id, type: 'proof_of_purchase'}, res=>{
            return res;   // Do everything in the 'then'
        })
            .then(res => {
                if (res.ok) {
                    const filename = res.headers.get('FileName');
                    res.blob().then(res => {
                        const blob = res;
                        blob.name = filename;
                        utilityFunctions.downloadFileAutomatically(blob);
                    });
                }
                this.setState({loading: false});
            }).catch((err)=> {
            console.log('fail', err);
            this.setState({loading: false});
        });
    };
    handleDelete = () => {
        const deviceToDelete = this.state.myDevices[this.state.showDeleteModal].id;  // Storing array id in showDeleteModal
        this.setState({loading: true, showDeleteModal: false});
        this.props.protectedFetch(config.backendServer+'deletedevice', {id: deviceToDelete}, errorFunctions.checkResponseSent)
            .then(res => {
                // Mimic a refresh of a page with the below
                this.componentDidMount();
            }).catch((err)=> {
            console.log('fail', err);
            this.setState({loading: false});
        });
    };

    render() {
        if (this.state.loading) {
            return <LoadingPage/>
        }
        const myDevices = this.state.myDevices;
        const that = this;

        return <div className={"page"}>
            <Card className={"my-device-info"}>
                <CardContent className={"my-device-info-container"}>
                    <MdInfo className={"my-device-info-icon"}/>
                    <span>To submit a warranty request, please email <a href={"mailto:service@roborock.com.au"}>service@roborock.com.au</a>.</span>
                </CardContent>
            </Card>
            {/* Add button for adding new 'My Device' */}
            {myDevices.length > 0 && <Link to="/addnewdevice" className={"my-devices-add-new my-devices-add-new-button-top"}>
                <FaPlus className={"my-devices-add-new-icon"}/>
                <div className={"my-devices-add-new-text"}>Add New</div>
            </Link>}
            <h2 className={"pageHeader"}>My Devices</h2>
            <div className={"pageContent clearfix"}>
                {myDevices.length === 0 && <div className={"none-screen"}>
                    <div>To get started - add your Roborock device by clicking the button below.</div>
                    <Link to={
                        this.state.noProfile
                            ? {pathname: "/profile", state: {goToDevice: true}}
                            : "/addnewdevice"
                    } className={"my-devices-add-new my-devices-add-new-main"}>
                        <FaPlus className={"my-devices-add-new-icon"}/>
                        <div className={"my-devices-add-new-text"}>Add New</div>
                    </Link>
                </div>}
                {myDevices.map(function(item, i) {
                    return <div className={"my-devices-active-container clearfix"} key={i}>
                        <h4 className={"my-devices-active-header"}>
                            {item.name}
                            <div className={"my-devices-active-header-icon-container"}>
                                <Link to={"/editdevice/"+item.id} className="my-devices-active-header-icon"><MdEdit/></Link>
                                <button className="my-devices-active-header-icon" onClick={() => that.setState({showDeleteModal: i})}><MdDelete/></button>
                            </div>
                        </h4>
                        <div className={"clearfix"}>
                            <div className={"my-devices-active-details-container"}>
                                <div className={"my-devices-active-details-header"}>Seller Details</div>
                                <div className={"my-devices-active-details-info"}>{item.seller_details}</div>
                            </div>
                            <div className={"my-devices-active-details-container"}>
                                <div className={"my-devices-active-details-header"}>Purchase Date</div>
                                <div className={"my-devices-active-details-info"}>{item.date}</div>
                            </div>
                            <div className={"my-devices-active-details-full-container"}>
                                <div className={"my-devices-active-details-header"}>Serial Number</div>
                                <div className={"my-devices-active-details-info"}>{item.serial_number}</div>
                            </div>
                            <div className={"my-devices-active-details-full-container"}>
                                <div className={"my-devices-active-details-header"}>Purchase info</div>
                                { !item.proof_of_purchase && <div className={"my-devices-active-details-info"}>{item.proof_of_purchase_text}</div> }
                                { item.proof_of_purchase && <button className="button" onClick={() => that.downloadProofOfPurchase(item.id)}>Download Proof of Purchase</button>}
                            </div>
                        </div>
                    </div>;
                })}
            </div>
            <Modal
                isOpen={this.state.showDeleteModal !== false}
                onRequestClose={()=>this.setState({showDeleteModal: false})}
                contentLabel={"Delete Device"}
                className={"confirm-modal"}
            >
                <button onClick={()=>this.setState({showDeleteModal: false})} className={"confirm-modal-close"}><MdClose/></button>
                <div className={"confirm-main-text"}>Are you sure you want to delete {this.state.myDevices[this.state.showDeleteModal] ? this.state.myDevices[this.state.showDeleteModal].name : "this device"}?</div>
                <div className={"confirm-sub-text"}>All associated warranty requests will also be deleted.</div>
                <div className={"approveDeclineSet"}>
                    <button onClick={this.handleDelete} className={"decline"}>Yes</button>
                    <button className={"neutral"} onClick={()=>this.setState({showDeleteModal: false})}>No</button>
                </div>
            </Modal>
            <Modal
                isOpen={this.state.showIosPopup}
                onRequestClose={()=>this.setState({showIosPopup: false})}
                contentLabel={"Download not available on mobile."}
                className={"info-modal"}
            >
                <button onClick={()=>this.setState({showIosPopup: false})} className={"info-modal-close"}><MdClose/></button>
                <div className={"info-main-text"}>Sorry but download is unavailable on mobile.</div>
                <div className={"info-sub-text"}>If you wish to see your proof of purchase document, please download on a computer.</div>
            </Modal>
        </div>
    }
}
