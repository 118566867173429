import React, { Component } from "react";
import "./Authentication.css";
import { Redirect } from "react-router-dom";
import config from "../../config";

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            invalidLoginTriggered: false,
            redirect: false
        };
    }


    validateForm() {
        if (this.state.email.length === 0) {
            this.setState({
                invalidLoginTriggered: "Please provide an email."
            });
        } else {
            return true;
        }

        return false;  //If not in else, then it's failed
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        const formValidated = this.validateForm();

        if (formValidated) {
            this.setState({ isLoading: true });

            let url = config.backendServer+'forgotpassword';

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    password: this.state.password,
                    email: this.state.email
                })
            }).then(response => {
                if (response.status === 200) {
                    this.setState({redirect: true, isLoading: false});
                } else if (response.status === 409) {
                    this.setState({
                        isLoading: false,
                        invalidLoginTriggered: "Email used does not exist.",
                        email: "",
                        password: ""
                    })
                } else {
                    alert("Problem with system. Please refresh and try again later.");
                }
            });
        }
    };
    render() {
        if (this.state.redirect) {
            return <Redirect to={"/forgotpasswordsuccess"}/>;
        }
        return (
            <div>
                <h2 className={"auth-page-header"}>Forgot Password</h2>
                <div className={"auth-page-info"}>
                    Enter your email to receive your password reset instructions
                </div>
                {this.state.invalidLoginTriggered && (
                    <div className={"incorrectAuthenticationText"}>
                        {this.state.invalidLoginTriggered}
                    </div>
                )}
                <form onSubmit={this.handleSubmit}>
                    <div className="auth-form-field">
                        <label>Email</label>
                        <input
                            type={"email"}
                            required={true}
                            id={"email"}
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </div>
                    <button className="auth-form-submit" type="submit">Send Reset Link</button>
                </form>
            </div>
        );
    }
}
