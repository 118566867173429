import React, { Component } from "react";
import "./UserProfile.css";
import "../common.css";
import "../simple-grid.css";
import config from '../../config';
import errorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";
import { MdClose } from "react-icons/md";
import {Link, Redirect} from "react-router-dom";
import Modal from 'react-modal';
Modal.setAppElement('body');

export default class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isLoading: false,
            showSuccess: false, // On update, show modal to show 'Data Saved'
            showFailure: false,
            fieldsEdited: false  // Only allow button click when something has been edited
        };

        this.inputFields = [
            {
                name: 'First Name',
                id: 'firstName',
                required: true,
                type: 'text',
                widthClass: 'col-12-sm col-6'
            }, {
                name: 'Last Name',
                id: 'lastName',
                required: true,
                type: 'text',
                widthClass: 'col-12-sm col-6'
            }, {
                name: 'Company (if any)',
                id: 'company',
                required: false,
                type: 'text',
                widthClass: 'col-12-sm col-12'
            }, {
                name: 'Address Line 1',
                id: 'address1',
                required: true,
                type: 'text',
                widthClass: 'col-12-sm col-12'
            }, {
                name: 'Address Line 2',
                id: 'address2',
                required: false,
                type: 'text',
                widthClass: 'col-12-sm col-12'
            }, {
                name: 'Suburb',
                id: 'suburb',
                required: true,
                type: 'text',
                widthClass: 'col-12-sm col-4'
            }, {
                name: 'Postcode',
                id: 'postcode',
                required: true,
                type: 'text',
                widthClass: 'col-12-sm col-4'
            }, {
                name: 'State',
                id: 'state',
                required: true,
                type: 'text',
                widthClass: 'col-12-sm col-4'
            }, {
                name: 'Mobile Phone Number',
                id: 'mobile',
                required: true,
                type: 'text',
                widthClass: 'col-12-sm col-12'
            }
        ];

    };
    componentDidMount() {
        this.retrieveProfile();
    };
    retrieveProfile = () => {
        this.setState({isLoading: true});
        this.props.protectedFetch(config.backendServer+'getuserprofile', null, errorFunctions.checkResponseThenJson)
            .then(res => {
                console.log(res);
                let stateToSet = {isLoading: false};
                for (const field of this.inputFields) {
                    stateToSet[field.id] = res[field.id] || '';
                }
                this.setState(stateToSet);
            }).catch(()=> {
            console.log('fail');
            this.setState({isLoading: false});
        });
    };
    handleInputChange = e => {
        const { id, value } = e.target;

        this.setState({
            [id]: value,
            fieldsEdited: true
        });
    };
    handleSubmit = e => {
        e.preventDefault();
        this.setState({isLoading: true});

        let dataToSend = {};
        for (const field of this.inputFields) {
            dataToSend[field.id] = this.state[field.id];
        }

        this.props.protectedFetch(config.backendServer+'setuserprofile', dataToSend, res => res.ok)
            .then(res => {
                console.log(res);
                // On resolved and rejected, need to take off loading screen
                this.setState({showSuccess: true, isLoading: false, fieldsEdited: false});
            }).catch(()=> {
            this.setState({showFailure: true, isLoading: false, fieldsEdited: false});
        });

    };

    render() {
        if (this.state.isLoading) {
            return <LoadingPage/>
        }
        if (this.state.showSuccess && this.props.location.state && this.props.location.state.goToDevice) {
            // If success and coming from click on load (setting up profile view)
            return <Redirect to={"/addnewdevice"}/>
        }

        const inputFields = this.inputFields;
        return <div className={"page"}>
            <h2 className={"pageHeader"}>My Details</h2>
            <div className={"pageContent"}>
                <form onSubmit={this.handleSubmit}>
                    {inputFields.map((item, i) => {
                        if (item.type === 'text') {
                            return <div key={i} className={`form-field ${item.widthClass}`}>
                                <label>{item.name} <span title={item.required ? 'This field is required' : ''}>{item.required ? '*' : ''}</span></label>
                                <input
                                    type={"text"}
                                    value={this.state[item.id]}
                                    required={item.required}
                                    onChange={this.handleInputChange}
                                    id={item.id}
                                />
                            </div>
                        }
                        return null;
                    })}
                    <button
                        className="form-submit"
                        type="submit"
                        disabled={!this.state.fieldsEdited}
                    >{(this.props.location.state && this.props.location.state.goToDevice) ? 'Next' : 'Update'}</button>
                    <Link to={"/"} className={"form-cancel"}>Cancel</Link>
                </form>
            </div>
            <Modal
                isOpen={this.state.showSuccess}
                onRequestClose={()=>this.setState({showSuccess: false})}
                contentLabel={"Profile has been updated."}
                className={"info-modal"}
            >
                <button onClick={()=>this.setState({showSuccess: false})} className={"info-modal-close"}><MdClose/></button>
                <div className={"info-main-text"}>Thank you</div>
                <div className={"info-sub-text"}>Your profile has been updated.</div>
            </Modal>
            <Modal
                isOpen={this.state.showFailure}
                onRequestClose={()=>this.setState({showFailure: false})}
                contentLabel={"Something has gone wrong."}
                className={"info-modal"}
            >
                <button onClick={()=>this.setState({showFailure: false})} className={"info-modal-close"}><MdClose/></button>
                <div className={"info-main-text"}>Something has gone wrong</div>
                <div className={"info-sub-text"}>Please refresh the page before continuing.</div>
            </Modal>
        </div>
    }
}
