import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import Login from './pages/AuthenticationPages/Login';
import './App.css';
import AuthContainer from "./pages/AuthenticationPages/AuthContainer";
import NotFound from "./pages/NotFound/NotFound";
import Signup from "./pages/AuthenticationPages/Signup";
import ForgotPassword from "./pages/AuthenticationPages/ForgotPassword";
import ResetPassword from "./pages/AuthenticationPages/ResetPassword";
import VerifySignup from "./pages/AuthenticationPages/VerifySignup";
import SignupVerified from "./pages/AuthenticationPages/SignupVerified";
import ForgotPasswordConfirm from "./pages/AuthenticationPages/ForgotPasswordConfirm";
import ResetPasswordConfirm from "./pages/AuthenticationPages/ResetPasswordConfirm";
import utilityFunctions from "./store/utilityFunctions";
import LoadingPage from "./components/Loading/Loading";
import config from "./config";
import NeedVerification from "./pages/AuthenticationPages/NeedVerification";
import MyDevices from "./pages/MyDevices/MyDevices";
import AddNewDevice from "./pages/MyDevices/AddNewDevice/AddNewDevice";
import PageWithNavbar from "./pageContainers/PageWithNavbar/PageWithNavbar";
import UserProfile from "./pages/UserProfile/UserProfile";

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isVerified: false  // Set to true if account is verified (in authentication check)
        };

        //Check if token exists, and if does set it
        const lgnToken =
            localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
        if (typeof lgnToken === "string") {
            this.state.isAuthenticated = lgnToken;
        }
    };
    componentDidMount() {
        this.validateUser();

        // If Google Analytics is on, load it in
        if (config.activateGoogleAnalytics) {
            utilityFunctions.loadScript(
                `https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsId}`,
                () => {
                    window.dataLayer = window.dataLayer || [];
                    window.gtag = function(){window.dataLayer.push(arguments);}
                    window.gtag('js', new Date());

                    window.gtag('config', 'UA-151355493-1');
                }
            )
        }
    }
    validateUser(callback) {
        // Check isAuthenticated and whether it is valid or not
        if (this.state.isAuthenticated) {
            // Need to validate token with server
            this.protectedFetch(config.backendServer+'validatetoken', null, res => res.ok)
                .then(res => {
                    // On resolved and rejected, need to take off loading screen
                    this.setState({isLoading: false, isVerified: true}, () => {
                        if (callback) {
                            callback();
                        }
                    });
                }).catch(()=> {
                this.setState({isLoading: false}, () => {
                    if (callback) {
                        callback();
                    }
                });
            });
        } else {
            // If not logged in, routes will handle rest
            this.setState({isLoading: false}, () => {
                if (callback) {
                    callback();
                }
            });
        }
    }
    userHasAuthenticated = (authenticated, rememberMe, callback) => {
        this.setState({ isAuthenticated: authenticated });
        if (authenticated) {
            if (rememberMe) {
                localStorage.setItem("lgn", authenticated);
            } else {
                utilityFunctions.setCookie("lgn", authenticated);
            }
        }
        this.validateUser(() => {
            if (callback) {
                callback();
            }
        });
    };
    handleLogout = event => {
        this.userHasAuthenticated(false);
        this.setState({ isAuthenticated: null });
        utilityFunctions.setCookie("lgn", "", -20); //Setting expires to past should delete cookie
        localStorage.removeItem("lgn");
    };

    protectedFetch = (url, data, checkFunction, formData) => {
        const token = this.state.isAuthenticated;
        const logout = this.handleLogout;
        return new Promise(function (resolve, reject) {
            let options;
            if (formData) {  // True / false
                options = {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    body: data
                };
            } else {
                options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(data || {})
                };
            }
            fetch(url, options).then(response => {
                // Check if token failure occurred - if so then trigger logout. Else use checkFunction
                if (response.status === 403) {
                    reject('token invalid');
                    logout();
                    return null;
                } else if (response.status === 413) {
                    // User is not verified, take to 'Account needs to be verified page'
                    reject('not verified');
                    return null;
                } else {
                    return checkFunction(response);
                }
            }).then(response=> {
                if (response) {
                    resolve(response);
                } else {
                    reject('check function failed');
                }
            });
        });
    };

    render() {
        if (this.state.isLoading) {
            return <LoadingPage/>
        }
        return (
            <Router>
                <div style={{width: "100%", height: "100%"}}>
                    <Switch>
                        <ProtectedRoute exact path="/" test={this.state.isAuthenticated && this.state.isVerified}
                                        goToOnFailure={"/needsverification"}
                                        render={(props) =>
                                            <PageWithNavbar
                                                logout={this.handleLogout}
                                            >
                                                <MyDevices protectedFetch={this.protectedFetch}/>
                                            </PageWithNavbar>}/>
                        <ProtectedRoute exact path="/addnewdevice" test={this.state.isAuthenticated && this.state.isVerified}
                                        goToOnFailure={"/needsverification"}
                                        render={(props) =>
                                            <PageWithNavbar
                                                logout={this.handleLogout}
                                            >
                                            <AddNewDevice
                                                protectedFetch={this.protectedFetch}
                                            />
                                            </PageWithNavbar>}/>
                        <ProtectedRoute exact path="/editdevice/:id" test={this.state.isAuthenticated && this.state.isVerified}
                                        goToOnFailure={"/needsverification"}
                                        render={(props) =>
                                            <PageWithNavbar
                                                logout={this.handleLogout}
                                            >
                                                <AddNewDevice protectedFetch={this.protectedFetch} {...props}/>
                                            </PageWithNavbar>}/>
                        <ProtectedRoute exact path="/profile" test={this.state.isAuthenticated && this.state.isVerified}
                                        goToOnFailure={"/needsverification"}
                                        render={(props) =>
                                            <PageWithNavbar
                                                logout={this.handleLogout}
                                            >
                                                <UserProfile
                                                    protectedFetch={this.protectedFetch}
                                                    {...props}
                                                />
                                            </PageWithNavbar>}/>
                        <ProtectedRoute exact path="/needsverification" test={this.state.isAuthenticated}
                                        goToOnFailure={"/login"}
                                        render={(props) =>
                                            <AuthContainer><NeedVerification protectedFetch={this.protectedFetch} {...props}/></AuthContainer>}/>
                        <ProtectedRoute exact path="/login" test={true}
                                        goToOnFailure={"/notfound"}
                                        render={(props) => <AuthContainer><Login onLogin={this.userHasAuthenticated} {...props}/></AuthContainer>}/>
                        <ProtectedRoute exact path="/signup" test={true}
                                        goToOnFailure={"/notfound"}
                                        render={(props) => <AuthContainer><Signup {...props}/></AuthContainer>}/>
                        <ProtectedRoute exact path="/signupsuccess" test={true}
                                        goToOnFailure={"/notfound"}
                                        render={(props) => <AuthContainer><VerifySignup {...props}/></AuthContainer>}/>
                        <ProtectedRoute exact path="/verify/:token" test={true}
                                        goToOnFailure={"/notfound"}
                                        render={(props) => <AuthContainer><SignupVerified {...props}/></AuthContainer>}/>
                        <ProtectedRoute exact path="/forgotpassword" test={true}
                                        goToOnFailure={"/notfound"}
                                        render={(props) => <AuthContainer><ForgotPassword {...props}/></AuthContainer>}/>
                        <ProtectedRoute exact path="/forgotpasswordsuccess" test={true}
                                        goToOnFailure={"/notfound"}
                                        render={(props) => <AuthContainer><ForgotPasswordConfirm {...props}/></AuthContainer>}/>
                        <ProtectedRoute exact path="/resetpassword/:token" test={true}
                                        goToOnFailure={"/notfound"}
                                        render={(props) => <AuthContainer><ResetPassword {...props}/></AuthContainer>}/>
                        <ProtectedRoute exact path="/resetpasswordsuccess" test={true}
                                        goToOnFailure={"/notfound"}
                                        render={(props) => <AuthContainer><ResetPasswordConfirm {...props}/></AuthContainer>}/>
                        {/* Finally, catch all unmatched routes */}
                        <Route component={NotFound}/>
                    </Switch>
                    {/* Below route renders on every page - just want to use it to send to Google Analytics etc */}
                    <Route path="/" render={({location}) => {
                        if (config.activateGoogleAnalytics && typeof window.gtag === 'function') {
                            window.gtag('config', config.googleAnalyticsId, {
                                'page_location': window.location.href,
                                'page_path': location.pathname
                            });
                        }
                        return null;
                    }}/>
                </div>
            </Router>
        );
    }
}

//Show if route shouldn't be available
class ProtectedRoute extends React.Component {
    render() {
        const {component: Component, ...props} = this.props;
        return (
            <Route
                {...props}
                render={props => (
                    !!this.props.test ?
                        this.props.render(this.props) :
                        <Redirect to={this.props.goToOnFailure}/>
                )}
            />
        )
    }
}

