import React, { Component } from "react";
import "./Authentication.css";
import { Redirect } from "react-router-dom";
import config from "../../config";

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            password: "",
            confirmPassword: "",
            invalidLoginTriggered: false,
            redirect: false
        };
    }

    validateForm() {
        if (this.state.password.length === 0) {
            this.setState({
                invalidLoginTriggered: "Please provide a password."
            });
        } else if (this.state.password !== this.state.confirmPassword) {
            this.setState({
                invalidLoginTriggered: "Passwords do not match."
            });
        } else {
            return true;
        }

        return false;  //If not in else, then it's failed
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        const formValidated = this.validateForm();

        if (formValidated) {
            this.setState({ isLoading: true });
            let url = config.backendServer+'resetpassword';

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    password: this.state.password,
                    token: this.props.computedMatch.params.token
                })
            })
                .then(response => {
                    if (response.status === 200) {
                        this.setState({redirect: true});
                    } else {
                        alert("Problem with system. Please close window and try again later.");
                    }
                });
        }
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={"/resetpasswordsuccess"}/>;
        }
        return (
            <div>
                <h2 className={"auth-page-header"}>Reset Password</h2>
                {this.state.invalidLoginTriggered && (
                    <div className={"incorrectAuthenticationText"}>
                        {this.state.invalidLoginTriggered}
                    </div>
                )}
                <form onSubmit={this.handleSubmit}>
                    <div className="auth-form-field">
                        <label>New Password</label>
                        <input
                            type={"password"}
                            required={true}
                            id={"password"}
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="auth-form-field">
                        <label>Confirm New Password</label>
                        <input
                            type={"password"}
                            required={true}
                            id={"confirmPassword"}
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                        />
                    </div>
                    <button className="auth-form-submit" type="submit">Reset</button>
                </form>
            </div>
        );
    }
}
