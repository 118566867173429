import React, { Component } from "react";
import "./AddNewDevice.css";
import "../../common.css";
import config from '../../../config';
import {Link, Redirect} from "react-router-dom";
import HalfPageImageRight from "../../../pageContainers/halfPageImageRight/halfPageImageRight";
import LoadingPage from "../../../components/Loading/Loading";
import errorFunctions from "../../../store/errorFunctions";

export default class AddNewDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proofOfPurchase: 'yes',
            redirect: false,
            isLoading: false,
            id: null,
            deviceName: 'Roborock S6',  // Default to top item in options list (shortcut work)
            dateOfPurchase: '',
            sellerDetails: '',
            serialNumber: '',
            textProofOfPurchase: ''
        };

    }
    componentDidMount() {
        if (this.props.computedMatch) {
            this.setState({id: this.props.computedMatch.params.id, isLoading: true});
            this.retrieveDetails(this.props.computedMatch.params.id);
        }
    };
    retrieveDetails(id) {
        // Rather than make a new endpoint, just use devices endpoint and filter here
        this.props.protectedFetch(config.backendServer+'getdevices', null, errorFunctions.checkResponseThenJson)
            .then(res => {
                const filteredDevices = res.filter((item) => {
                    return item.id == id;
                });
                this.setState({
                    isLoading: false,
                    deviceName: filteredDevices[0].name,
                    dateOfPurchase: filteredDevices[0].date,
                    sellerDetails: filteredDevices[0].seller_details,
                    serialNumber: filteredDevices[0].serial_number,
                    proofOfPurchase: filteredDevices[0].proof_of_purchase ? 'yes' : 'no',
                    textProofOfPurchase: filteredDevices[0].proof_of_purchase_text
                });
            }).catch(()=> {
            console.log('fail');
            this.setState({isLoading: false});
        });
    };
    handleRadioChange = e => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    };
    handleInputChange = e => {
        const { id, value } = e.target;

        this.setState({
            [id]: value
        });
    };
    handleFileUpload = e => {
        // TODO - verify file upload to make sure it's good
    };
    handleSubmit = e => {
        this.setState({isLoading: true});
        e.preventDefault();
        let formData = new FormData();
        formData.append('name', this.state.deviceName);
        formData.append('date', this.state.dateOfPurchase);
        formData.append('details', this.state.sellerDetails);
        formData.append('serial', this.state.serialNumber);
        formData.append('proof', this.state.proofOfPurchase);
        if (this.state.proofOfPurchase === 'yes') {
            formData.append('file', document.getElementById("uploadProofOfPurchase").files[0]);
        } else {
            formData.append('proofText', this.state.textProofOfPurchase);
        }

        if (this.state.id) {
            formData.append('id', this.state.id);
            // If id in state, then existing device
            this.props.protectedFetch(config.backendServer+'editdevice', formData, res => res.ok, true)
                .then(res => {
                    console.log(res);
                    // On resolved and rejected, need to take off loading screen
                    this.setState({redirect: true, isLoading: false});
                }).catch(()=> {

            });
        } else {
            // If no id in state, then new device
            this.props.protectedFetch(config.backendServer+'addnewdevice', formData, res => res.ok, true)
                .then(res => {
                    console.log(res);
                    // On resolved and rejected, need to take off loading screen
                    this.setState({redirect: true, isLoading: false});
                }).catch(()=> {

            });
        }

    };

    render() {
        if (this.state.isLoading) {
            return <LoadingPage small={true}/>;
        } else if (this.state.redirect) {
            return <Redirect to={"/"}/>;
        }
        const inputFields = [
            {
                name: 'Device Name',
                id: 'deviceName',
                info: null,
                type: 'select',
                options: [
                    'Roborock S6',
                    'Roborock S6 Pure',
                    'Roborock S5 Max',
                    'Roborock S50',
                    'Roborock H6',
                    'Roborock H6 Adapt',
                    'Roborock E35',
                    'Roborock E20',
                    'Other'
                ]
            }, {
                name: 'Date of Purchase',
                id: 'dateOfPurchase',
                info: null,
                type: 'text'
            }, {
                name: 'Seller details',
                id: 'sellerDetails',
                info: 'Where did you purchase this from? (include seller details)',
                type: 'text'
            }, {
                name: 'Serial Number',
                id: 'serialNumber',
                info: 'The serial number of the Roborock is on the label located on the box of the Roborock. Please provide the Serial Number situated on the left side of the SKU barcode (usually starts with the letter R)',
                type: 'text'
            }, {
                name: 'Proof of Purchase',
                id: 'proofOfPurchase',
                info: 'All claims must have proof of purchase in order for us to verify the warranty. We will not accept submissions with no proof.',
                type: 'yesNo'
            }
        ];
        return <HalfPageImageRight>
            <h2 className={"pageHeader"}>{this.state.id ? 'Edit Device' : 'Add New Device'}</h2>
            <div className={"pageContent"}>
                <form onSubmit={this.handleSubmit}>
                    {inputFields.map((item, i) => {
                        if (item.type === 'text') {
                            return <div key={i} className="form-field">
                                <label>{item.name}</label>
                                <input
                                    type={"text"}
                                    required={true}
                                    onChange={this.handleInputChange}
                                    id={item.id}
                                    value={this.state[item.id]}
                                />
                                <div className={"add-new-device-input-info"}>{item.info}</div>
                            </div>
                        } else if (item.type === 'yesNo') {
                            return <div key={i} className={"form-field add-new-device-radio-field"}>
                                <label>{item.name}</label>
                                <input type="radio" name={item.id} value="yes" onChange={this.handleRadioChange} required defaultChecked={this.state.proofOfPurchase === 'yes'}/> Yes
                                <input type="radio" name={item.id} value="no"  onChange={this.handleRadioChange} required defaultChecked={this.state.proofOfPurchase === 'no'}/> No
                            </div>
                        } else if (item.type === 'select') {
                            return <div key={i} className="form-field">
                                <label>{item.name}</label>
                                <select
                                    id={item.id}
                                    onChange={this.handleInputChange}
                                    value={item.options.indexOf(this.state[item.id]) === -1 ? 'Other' : this.state[item.id]}
                                >
                                    {item.options.map((option, j) => {
                                        return <option key={j}>{option}</option>
                                    })}
                                </select>
                                    {/* If select option is other, replace state with name in input. Fancy OR statement handles this */}
                                {(this.state[item.id] === 'Other' || item.options.indexOf(this.state[item.id]) === -1) && <input
                                    type={"text"}
                                    required={true}
                                    placeholder={"Type device name here"}
                                    onChange={this.handleInputChange}
                                    id={item.id}
                                    value={this.state[item.id]==='Other' ? '' : this.state[item.id]}
                                />}
                                <div className={"add-new-device-input-info"}>{item.info}</div>
                            </div>
                        }
                        return null;
                    })}
                    {/* I'm a little lazy, hardcoding these custom variables in */}
                    {this.state.proofOfPurchase === 'yes' && <div className={"form-field add-new-device-proof-field"}>
                        <label>Please upload your proof of purchase</label>
                        <input
                            type={"file"}
                            id={"uploadProofOfPurchase"}
                            required={true}
                            onChange={this.handleFileUpload}
                        />
                    </div> }
                    {this.state.proofOfPurchase === 'no' && <div className={"form-field add-new-device-proof-field"}>
                        <label>Please provide details to prove where the purchase was from</label>
                        <textarea
                            required={true}
                            onChange={this.handleInputChange}
                            id={"textProofOfPurchase"}
                            value={this.state.textProofOfPurchase}
                        />
                    </div> }
                    <div className={"add-new-device-proof-info"}>All warranty claims must have proof of purchase in order for us to verify the warranty. We will not accept submissions with no proof.</div>
                    <button
                        className="form-submit"
                        type="submit"
                    >Submit</button>
                    <Link to={"/"} className={"form-cancel"}>Cancel</Link>
                </form>
            </div>
        </HalfPageImageRight>
    }
}
