import React, { Component } from "react";
import rightBackground from "../../img/robot-background-2.jpg";
import './halfPageImageRight.css';

export default class HalfPageImageRight extends Component {
    resize = () => {
        this.forceUpdate();
    };
    componentDidMount() {
        window.addEventListener("resize", this.resize);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    render() {
        let width = window.innerWidth;
        let backgroundImgStyle = {
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${this.props.background || rightBackground})`
        };

        return <div className={"half-page-image-right-container clearfix"}>
            <div className={"half-page-image-right-content-side"}>
                <div className={"half-page-image-right-content-holder"}>{this.props.children}</div>
            </div>
            {width > 450 && (
                <div style={backgroundImgStyle} className={"half-page-image-right-img-side"}/>
            )}
        </div>
    }
}
