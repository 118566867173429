import React, { Component } from "react";
import "./Authentication.css";

export default class VerifySignup extends Component {
    render() {
        let emailProblem;
        if (this.props.location.state) {
            emailProblem = this.props.location.state.emailProblem;
        }
        return (
            <div>
                <h2 className={"auth-page-header"}>You're nearly there!</h2>
                <div className={"auth-page-info"}>
                    Your account has been made. Please verify it by clicking the activation link that has been sent to your email.
                </div>
                {emailProblem && <div className={"auth-page-info"}>
                     If your email hasn't arrived after 10 minutes, please check your Spam folder.
                </div>}
            </div>
        );
    }
}
