import React, { Component } from "react";
import "./Authentication.css";

export default class ForgotPasswordConfirm extends Component {
    render() {
        return (
            <div>
                <h2 className={"auth-page-header"}>Forgot Password</h2>
                <div className={"auth-page-info"}>
                    An email has been sent. Please follow the instructions in your email received.
                </div>
            </div>
        );
    }
}
