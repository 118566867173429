const dev = {
    backendServer: 'https://api.roborockservice.com.au/',
    activateGoogleAnalytics: false
};
const uat = {
    backendServer: 'https://api.roborock.prasadh.com/',
    activateGoogleAnalytics: false
};
const prod = {
    backendServer: 'https://api.roborockservice.com.au/',
    activateGoogleAnalytics: true
};

const config = process.env.REACT_APP_ENV === 'prod' ? prod :
    process.env.REACT_APP_ENV === 'uat' ? uat : dev;

export default {
    // Add common config values here
    googleAnalyticsId: 'UA-151355493-1',
    ...config
};
