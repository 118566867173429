import React, { Component } from "react";
import "./Authentication.css";
import {Link, Redirect} from "react-router-dom";
import config from '../../config';
import { MdClose } from "react-icons/md";
import { FaExclamationCircle } from "react-icons/fa";
import Modal from 'react-modal';
Modal.setAppElement('body');

export default class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: "",
            confirmPassword: "",
            iAgree: false,
            invalidLoginTriggered: false,
            redirect: false,
            loading: false,
            emailAlreadyInUse: false
        };
    }

    validateForm() {
        if (this.state.email.length === 0) {
            this.setState({
                invalidLoginTriggered: "Please provide an email."
            });
        } else if (this.state.password.length === 0) {
            this.setState({
                invalidLoginTriggered: "Please provide a password."
            });
        } else if (this.state.password !== this.state.confirmPassword) {
            this.setState({
                invalidLoginTriggered: "Passwords do not match."
            });
        } else if (!this.state.iAgree) {
            this.setState({
                invalidLoginTriggered: "Please agree to the Terms of Service and Privacy Policy."
            });
        }
        else {
            return true;
        }

        return false;  //If not in else, then it's failed
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({loading: true});

        const formValidated = this.validateForm();

        if (formValidated) {
            this.setState({ isLoading: true });
            let url = config.backendServer+'signup';

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    password: this.state.password,
                    email: this.state.email
                })
            })
                .then(response => {
                    if (response.status === 200) {
                        response.text().then((text) => {
                            this.setState({redirect: true, emailProblem: text});
                        });
                    } else if (response.status === 409) {
                        this.setState({
                            emailAlreadyInUse: true,
                            email: "",
                            password: "",
                            confirmPassword: ""
                        })
                    } else {
                        alert("Problem with system. Please refresh and try again later.");
                    }
                });
        }
    };

    handleCheckboxChange = event => {
        this.setState({
            [event.target.id]: event.target.checked
        });
    };
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.emailAlreadyInUse}
                    onRequestClose={()=>this.setState({emailAlreadyInUse: false})}
                    contentLabel={"Email is already in use."}
                    className={"auth-error-modal"}
                >
                    <button onClick={()=>this.setState({emailAlreadyInUse: false})} className={"auth-modal-close"}><MdClose/></button>
                    <div className={"auth-error-icon"}><FaExclamationCircle/></div>
                    <div className={"auth-error-main-text"}>Sorry</div>
                    <div className={"auth-error-sub-text"}>This email is already in use. Please try another email.</div>
                </Modal>
                {this.state.redirect && <Redirect to={{pathname: "/signupsuccess", state: {emailProblem: this.state.emailProblem}}}/>}
                <h2 className={"auth-page-header"}>Create account</h2>
                {this.state.invalidLoginTriggered && (
                    <div className={"incorrectAuthenticationText"}>
                        {this.state.invalidLoginTriggered}
                    </div>
                )}
                <form onSubmit={this.handleSubmit}>
                    <div className="auth-form-field">
                        <label>Email</label>
                        <input
                            type={"email"}
                            required={true}
                            id={"email"}
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="auth-form-field">
                        <label>Password</label>
                        <input
                            type={"password"}
                            required={true}
                            id={"password"}
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="auth-form-field">
                        <label>Confirm Password</label>
                        <input
                            type={"password"}
                            required={true}
                            id={"confirmPassword"}
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className={"auth-options-section clearfix"}>
                        <label className="auth-checkbox-field">
                            I agree to the{" "}
                            <a target={"blank"} href={"https://roborock.com.au/pages/service-warranty"}>Terms of Service</a>
                            {" "}and{" "}
                            <a target={"blank"} href={"https://roborock.com.au/pages/privacy-policy"}>Privacy Policy.</a>
                            <input type="checkbox"
                                   id="iAgree"
                                   onChange={this.handleCheckboxChange}
                                   checked={this.state.iAgree}
                            />
                            <span className="checkmark"/>
                        </label>
                    </div>
                    <button className="auth-form-submit" type="submit">Sign up</button>
                    <div className={"auth-extra-link-section"}>
                        Already have an account? <Link to={"/login"}>Login</Link>
                    </div>
                </form>
            </div>
        );
    }
}
