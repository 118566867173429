import React, { Component } from "react";
import "./Authentication.css";
import config from '../../config';

export default class NeedVerification extends Component {
    resendEmail =() => {
        this.props.protectedFetch(config.backendServer+'resendverification', null, res => res.ok)
            .then(res => {
                alert('Verification email has been resent.');
            }).catch(()=> {
                alert('Problem with webpage. Please close browser window and try again later.');
            });
    };

    render() {
        return (
            <div>
                <h2 className={"auth-page-header"}>Your account needs to be verified</h2>
                <div className={"auth-page-info"} style={{textAlign: 'left'}}>
                    Please verify your account by opening up the email we sent from 'Roborock Australia'. Make sure to check your Junk mail folder!
                </div>
                <div className={"auth-page-info"} style={{textAlign: 'left'}}>
                    You can click the button below to resend the email.
                </div>
                <button type={"button"} className="auth-form-submit" onClick={this.resendEmail}>Resend verification email</button>
            </div>
        );
    }
}
