import React, { Component } from "react";
import "./Authentication.css";
import config from '../../config';
import { Link } from "react-router-dom";
import ErrorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";

export default class SignupVerified extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isVerified: false
        };
    }
    componentDidMount() {
        this.checkToken();
    }

    checkToken() {
        const token = this.props.computedMatch.params.token;
        const url = config.backendServer+'verify';
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: token
            })
        }).then(ErrorFunctions.checkResponseSent)
            .then((ok) => {
                this.setState({
                    isVerified: ok,
                    isLoading: false
                })
            });

    }

    render() {
        if (this.state.isLoading) {
            return <LoadingPage small={true}/>
        }
        else if (this.state.isVerified) {
            return (
                <div>
                    <h2 className={"auth-page-header"}>Account Verified</h2>
                    <div className={"auth-page-info"}>
                        Your account is ready to be used. Login now by clicking the button below.
                    </div>
                    <Link className="auth-form-submit" to={"/login"}>Login</Link>
                </div>
            );
        }
        else {
            return (
                <div>
                    <h2 className={"auth-page-header"}>Sorry</h2>
                    <div className={"auth-page-info"}>
                        Something went wrong.
                    </div>
                    <Link className="auth-form-submit" to={"/"}>Home</Link>
                </div>
            );
        }
    }
}
